<div class="main-mission">
  <div class="container container-div">
    <div class="row mg-0">
      <div
        class="col-12 col-lg-6 col-xl-6 justify-content-center mission-header hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Our Mission
      </div>
      <div
        class="col-12 col-lg-6 col-xl-6 justify-content-center misson-text hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        <div>Our mission is to build future technology that help people.</div>
        <div>Building a great team is our current focus.</div>
        <div>Feel free to contact us, we will love to work with you.</div>
      </div>
    </div>
  </div>
</div>
