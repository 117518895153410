<ngb-carousel
  #carousel
  [interval]="sliderTimer"
  [pauseOnHover]="pauseOnHover"
  (slide)="onSlide($event)"
>
  <ng-template ngbSlide *ngFor="let img of imagesData; index as i">
    <div
      class="carousel-caption"
      animateOnScroll
      animationName="animate__animated animate__fadeInRight"
    >
      <p class="review-text">{{ img.message }}</p>
      <img
        *ngIf="img.profileUrl"
        loading="lazy"
        class="user-icon"
        [src]="img.profileUrl"
      />
      <i
        *ngIf="!img.profileUrl"
        class="fa fa-user-circle-o user-icon"
        aria-hidden="true"
      ></i>
      <p class="review-name">{{ img.fullName }}</p>
      <p class="review-position">{{ img.position }}</p>
    </div>
  </ng-template>
</ngb-carousel>
