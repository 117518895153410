<div class="footer-main" id="footer">
  <div class="footer-top row mg-0">
    <div class="offset-lg-2 col-lg-8 offset-xl-2 col-xl-8 offset-2 col-8">
      <div class="row mg-0 right-main-div">
        <div
          class="left-side col-12 col-lg-6 col-xl-6 hide-on-init info-div"
          animateOnScroll
          animationName="animate__animated animate__fadeInLeft"
        >
          <div>
            <i class="fa fa-envelope-o" aria-hidden="true"></i
            >infielight@gmail.com
          </div>
          <div>
            <i class="fa fa-map-marker" aria-hidden="true"></i>Amravati,
            Maharashtra, India
          </div>
        </div>
        <div class="right-side col-12 col-lg-6 col-xl-6">
          <a
            class="social-link hide-on-init"
            href="https://www.facebook.com/Infielightai-115006926995943"
            target="_blank"
            rel="noopener noreferrer"
            animateOnScroll
            animationName="animate__animated animate__zoomIn"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a
            class="social-link hide-on-init"
            animateOnScroll
            target="_blank"
            rel="noopener noreferrer"
            animationName="animate__animated animate__zoomIn"
          >
            <i class="fa fa-linkedin"></i>
          </a>
          <a
            class="social-link hide-on-init"
            animateOnScroll
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/infielight.ai/"
            animationName="animate__animated animate__zoomIn"
          >
            <i class="fa fa-instagram"></i>
          </a>
          <a
            class="social-link hide-on-init"
            animateOnScroll
            target="_blank"
            href="https://t.me/infielight"
            rel="noopener noreferrer"
            animationName="animate__animated animate__zoomIn"
          >
            <i class="fa fa-telegram"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-xl-2 col-2"></div>
  </div>
  <div class="footer-bottom row mg-0">
    <div class="offset-lg-2 col-lg-8 offset-xl-2 col-xl-8 offset-2 col-8">
      <p
        class="copy-right-text hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__zoomIn"
      >
        Copyright ©{{ currentDate }} All rights reserved by
        {{ "website_global.app_name" | translate }}
      </p>
    </div>
    <div class="col-lg-2 col-xl-2 col-2"></div>
  </div>
</div>
