import { Component, OnInit, Input } from '@angular/core';
import { Utility } from 'src/app/dashboard/shared/util/app-utils';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
})
export class BlogCardComponent implements OnInit {
  @Input() blogData: any;
  utility = Utility;
  constructor() {}

  ngOnInit(): void {}
}
