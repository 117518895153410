<div class="error-main">
  <div class="center-div">
    <img
      loading="lazy"
      class="error-img"
      alt="404 error"
      src="/assets/images/error_404.jpeg"
    />
    <a class="home-link" href="/"
      ><i class="fa fa-text-right fa-arrow-left" aria-hidden="true"></i
      >{{ "error_page.go_back" | translate }}</a
    >
  </div>
</div>
