<div class="main-home">
  <div class="container container-home">
    <div class="row align-items-center justify-content-start">
      <div class="col-lg-10 col-md-10">
        <div class="main-text-div">
          <h3
            class="main-text hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInLeft"
          >
            We, together the possibility starts here and goes till infinity
          </h3>
          <button
            class="filled-btn mt-4 hide-on-init"
            (click)="goToPortfolio()"
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
          >
            Why {{ "website_global.app_name" | translate }}?
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
