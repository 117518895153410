<div class="main-service">
  <div class="container container-div">
    <div
      class="working-header justify-content-center mb-4 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      What technology we can work on?
    </div>
    <div
      class="working-header-text justify-content-center mb-5 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      We are <b>problem solvers,</b> we learn anything to solve a problem
    </div>

    <div class="row mg-0 card-size-div">
      <div
        class="col-12 col-lg-12 tech-name hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Machine Learning
      </div>
      <div
        class="col-12 col-lg-12 tech-list hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        NLP, Image Classification, Audio Classification, Pytorch, Opencv,
        Tensorflow, Data Preprossing, Transfers Learning, Django, Flask,
        sklearn, matplotlib, numpy, pandas, keras.
      </div>
      <div
        class="col-12 col-lg-12 tech-name hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Reinforcement Learning and Research
      </div>
      <div
        class="col-12 col-lg-12 tech-list hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Pytorch, Pygame, Box3d, Pybullet, openai gym, Unity3d.
      </div>
      <div
        class="col-12 col-lg-12 tech-name hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Web Development
      </div>
      <div
        class="col-12 col-lg-12 tech-list hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Angular, React, PHP, Node & Express, Flask, Django, Typescript, AWS,
        Strapi, GraphQL, SQL, MongoDB.
      </div>
      <div
        class="col-12 col-lg-12 tech-name hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Native Development
      </div>
      <div
        class="col-12 col-lg-12 tech-list hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        Android(Java, kotlin), React native, flatter, Ionic.
      </div>
    </div>
  </div>
</div>
