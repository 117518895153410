import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constants';
import { SnackbarUtil } from '../dashboard/shared/util/snackbar-util';
import { Subject } from 'rxjs';

export enum PageNoEnum {
  Home = 1,
  SERVICE = 2,
  PORTFOLIO = 3,
  BLOG = 4,
  CONTACT = 5,
  ABOUT = 6,
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  navChangeListener = new Subject<any>();
  constructor(private http: HttpClient) {}

  async addContact(requestData) {
    const response: any = await this.http
      .post<any>(ApiConstants.URL_ADD_CONTACT, {
        ...requestData,
      })
      .toPromise();
    if (response) {
      SnackbarUtil.showMessage('Thanks We Will Contact You!', false);
    } else {
      SnackbarUtil.showMessage('Something Went Wrong!');
    }
    return [];
  }

  async getReviews() {
    const response: any = await this.http
      .get<any>(ApiConstants.URL_GET_REVIEWS)
      .toPromise();
    return response;
  }

  async getBlogs() {
    const response: any = await this.http
      .get<any>(ApiConstants.URL_GET_BLOGS)
      .toPromise();
    return response;
  }

  async getSignleBlog(id) {
    const response: any = await this.http
      .get<any>(`${ApiConstants.URL_GET_BLOGS}/${id}`)
      .toPromise();
    return response;
  }

  getNavChangeListener() {
    return this.navChangeListener.asObservable();
  }
}
