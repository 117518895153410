import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-work',
  templateUrl: './featured-work.component.html',
  styleUrls: ['./featured-work.component.scss']
})
export class FeaturedWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
