<div
  class="card-main hide-on-init"
  animateOnScroll
  animationName="animate__animated animate__fadeInUp"
  (mouseover)="mouseOvered = true"
  (mouseout)="mouseOvered = false"
  *ngIf="cardData"
>
  <!-- <div class="person-img">
    <img loading="lazy" [src]="cardData.imageUrl" alt="" />
    <div
      class="hover_inner"
      [class.animate__animated]="mouseOvered"
      [class.animate__fadeInUp]="mouseOvered"
    >
      <ul>
        <li>
          <a class="social-link social-link-op" [href]="cardData.fbUrl">
            <i class="fa fa-facebook"></i>
          </a>
        </li>
        <li>
          <a class="social-link social-link-op" [href]="cardData.liUrl">
            <i class="fa fa-linkedin"></i>
          </a>
        </li>
        <li>
          <a class="social-link social-link-op" [href]="cardData.gplueUrl">
            <i class="fa fa-google-plus"></i>
          </a>
        </li>
      </ul>
    </div>
  </div> -->
  <h3 class="mt-3 card-name">{{ cardData.fullName }}</h3>
  <p class="card-p">{{ cardData.position }}</p>
</div>
