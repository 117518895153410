import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './dashboard/main/home/home.component';
import { ServicesComponent } from './dashboard/main/services/services.component';
import { PortfolioComponent } from './dashboard/main/portfolio/portfolio.component';
import { BlogsComponent } from './dashboard/main/blogs/blogs.component';
import { ContactComponent } from './dashboard/main/contact/contact.component';
import { AboutComponent } from './dashboard/main/about/about.component';
import { SingleBlogComponent } from './dashboard/main/blogs/single-blog/single-blog.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'blogs/:id', component: SingleBlogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
