<div class="service">
  <div class="main-home">
    <div class="container container-home">
      <div class="row align-items-center justify-content-start">
        <div class="col-lg-10 col-md-10">
          <div class="main-text-div">
            <h3
              class="main-text hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            >
              Our Services
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-shared-services></app-shared-services>
</div>
