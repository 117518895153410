<div class="home">
  <app-home-main></app-home-main> <app-shared-services></app-shared-services>
  <app-about-us></app-about-us>
  <div class="our-mission-home-main">
    <app-our-mission></app-our-mission>
  </div>

  <app-how-we-work></app-how-we-work>
  <app-contact-form></app-contact-form>
</div>
