import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { Router } from '@angular/router';
import { RouteConstants } from 'src/app/constants/app-routes-constants';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blogData = [];
  constructor(private contactService: ContactService, private router: Router) {}

  ngOnInit(): void {
    this.getBlogs();
  }

  async getBlogs() {
    this.blogData = await this.contactService.getBlogs();
  }

  async goToBlog(blogId) {
    this.router.navigate([`${RouteConstants.BLOGS}/${blogId}`]);
  }
}
