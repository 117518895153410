import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/constants/app-constants';
import { ApiConstants } from 'src/app/constants/api-constants';

export class Utility {
  public static translate: TranslateService;
  public static isFormVaild(formGroup: FormGroup) {
    return formGroup.status === AppConstants.STATUS_VAILD;
  }

  /**
   * To get error list from any form
   * @param formGroup form on which to check for error
   * @param keyMapping is used to map keys to a value that is shown on screen
   */
  public static getErrorListFromForm(formGroup: FormGroup, keyMapping: any) {
    const errorsArray = [];
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        formGroup.controls[key].markAsTouched();
        if (formGroup.controls[key].errors?.required) {
          errorsArray.push(
            `${this.translate.instant('util.the_text')} ${
              keyMapping[key]
            } ${this.translate.instant('util.is_required_text')}`
          );
        }
        if (
          formGroup.controls[key].errors?.email ||
          formGroup.controls[key].errors?.pattern
        ) {
          if (key === 'password' || key === 'confirmPassword') {
            errorsArray.push(
              `${this.translate.instant('util.the_text')} ${
                keyMapping[key]
              } ${this.translate.instant('util.password_not_valid_text')}`
            );
          } else {
            errorsArray.push(
              `${this.translate.instant('util.the_text')} ${
                keyMapping[key]
              } ${this.translate.instant('util.is_not_valid_text')}`
            );
          }
        }
      }
    }
    return errorsArray;
  }

  public static getServerImageUrl(url) {
    return `${ApiConstants.API_SERVER}${url}`;
  }
}
