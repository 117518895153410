<!-- A grey horizontal navbar that becomes vertical on small screens -->
<nav
  class="navbar navbar-main navbar-expand-md navbar-light bg-light nav-main"
  [class.sticky-nav-bar]="isScrolled"
  [class.animate__animated]="true"
  [class.animate__fadeInDown]="isScrolled"
>
  <a class="navbar-brand col-xl-2 col-lg-2" href="/home"
    ><img
      width="200"
      height="52"
      loading="eager"
      src="/assets/images/test.png"
      alt="Logo"
      class="logo-img"
    />
  </a>
  <button
    #menu
    class="navbar-toggler nav-toggle-btn"
    data-toggle="collapse"
    data-target="#navbarToggler"
    aria-controls="navbarToggler"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <i class="fa fa-bars" aria-hidden="true"></i>
  </button>
  <div class="is-top-nav-shown">
    <ul class="navbar-nav"></ul>
  </div>

  <div class="collapse navbar-collapse col-xl-8 col-lg-8" id="navbarToggler">
    <ul class="navbar-nav">
      <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToHome()"
          [class.selected]="selectedOption === 1"
        >
          {{ "nav_header.home" | translate }}
        </span>
      </li>
      <!-- <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToService()"
          [class.selected]="selectedOption === 2"
        >
          {{ "nav_header.servies" | translate }}
        </span>
      </li> -->
      <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToPortfolio()"
          [class.selected]="selectedOption === 3"
        >
          {{ "nav_header.portfolio" | translate }}
        </span>
      </li>
      <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToBlogs()"
          [class.selected]="selectedOption === 4"
        >
          {{ "nav_header.blog" | translate }}
        </span>
      </li>
      <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToContact()"
          [class.selected]="selectedOption === 5"
        >
          {{ "nav_header.contact" | translate }}
        </span>
      </li>
      <li class="nav-item">
        <span
          class="nav-btns"
          (click)="goToAbout()"
          [class.selected]="selectedOption === 6"
        >
          {{ "nav_header.about" | translate }}
        </span>
      </li>
    </ul>
  </div>
  <div class="col-xl-2 col-lg-2 hide-on-mobile">
    <li class="nav-item">
      <span class="nav-btns last-span" (click)="scroll()">
        {{ "nav_header.say_hello" | translate }}
      </span>
    </li>
  </div>
</nav>
