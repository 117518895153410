import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  reviewData = [];
  constructor(private contactService: ContactService) {}

  ngOnInit(): void {
    this.getReviews();
  }

  async getReviews() {
    const data = await this.contactService.getReviews();
    if (data && data.length) {
      this.reviewData = data;
    }
  }
}
