import { Component, OnInit } from '@angular/core';
import { ContactService, PageNoEnum } from 'src/app/services/contact.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  constructor(public contactService: ContactService) {}

  ngOnInit(): void {}

  goToBlogs() {
    this.contactService.navChangeListener.next(PageNoEnum.ABOUT);
  }
}
