import { Component, OnInit } from '@angular/core';
import { PageNoEnum, ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss'],
})
export class HomeMainComponent implements OnInit {
  constructor(public contactService: ContactService) {}

  ngOnInit(): void {}

  goToPortfolio() {
    this.contactService.navChangeListener.next(PageNoEnum.PORTFOLIO);
  }
}
