export class ApiConstants {
  public static API_SERVER = 'http://localhost:1337';
  public static URL_ADD_CONTACT;
  public static URL_GET_REVIEWS;
  public static URL_GET_BLOGS;

  static setData(ADDRESS) {
    ApiConstants.API_SERVER = ADDRESS;
    ApiConstants.URL_ADD_CONTACT = ApiConstants.API_SERVER + '/contacts';
    ApiConstants.URL_GET_REVIEWS = ApiConstants.API_SERVER + '/reviews';
    ApiConstants.URL_GET_BLOGS = ApiConstants.API_SERVER + '/blogs';
  }
}
