<div class="main-team">
  <div class="container container-div">
    <div
      class="team-header justify-content-center mb-4 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      Our Creative Team
    </div>
    <div
      class="team-header-text justify-content-center mb-5 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      We love to work on exciting things and with our great team we provide
      great quality products.
    </div>

    <div class="row mg-0">
      <div
        class="col-xl-3 col-md-6 col-lg-6 col-12 card-size-div"
        *ngFor="let card of cardData"
      >
        <app-profile-card [cardData]="card"></app-profile-card>
      </div>
    </div>
  </div>
</div>
