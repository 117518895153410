import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'],
})
export class OurTeamComponent implements OnInit {
  mouseOvered = false;
  cardData = [
    {
      imageUrl: '/assets/images/ceo.png',
      fullName: 'Shubham Dohare',
      position: 'Founder & CEO',
      fbUrl: '#',
      liUrl: '#',
      gplueUrl: '#',
    },
    {
      imageUrl: '/assets/images/ceo.png',
      fullName: 'Ashish Dohare',
      position: 'Founder & President',
      fbUrl: '#',
      liUrl: '#',
      gplueUrl: '#',
    },
    {
      imageUrl: '/assets/images/ceo.png',
      fullName: 'Nikhil Dohare',
      position: 'Designer',
      fbUrl: '#',
      liUrl: '#',
      gplueUrl: '#',
    },
    {
      imageUrl: '/assets/images/ceo.png',
      fullName: 'Pooja Jambulkar',
      position: 'Manager',
      fbUrl: '#',
      liUrl: '#',
      gplueUrl: '#',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
