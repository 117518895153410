import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavBarComponent } from './dashboard/nav-bar/nav-bar.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { MainComponent } from './dashboard/main/main.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeMainComponent } from './dashboard/shared/home-main/home-main.component';
import { SharedServicesComponent } from './dashboard/shared/services/shared-services.component';
import { AboutUsComponent } from './dashboard/shared/about-us/about-us.component';
import { FeaturedWorkComponent } from './dashboard/shared/featured-work/featured-work.component';
import { HowWeWorkComponent } from './dashboard/shared/how-we-work/how-we-work.component';
import { OurTeamComponent } from './dashboard/shared/our-team/our-team.component';
import { QuotesComponent } from './dashboard/shared/quotes/quotes.component';
import { ContactFormComponent } from './dashboard/shared/contact-form/contact-form.component';
import { HomeComponent } from './dashboard/main/home/home.component';
import { PortfolioComponent } from './dashboard/main/portfolio/portfolio.component';
import { BlogsComponent } from './dashboard/main/blogs/blogs.component';
import { ContactComponent } from './dashboard/main/contact/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfigService } from './app-config.service';
import { ToastrModule } from 'ngx-toastr';
import { ServicesComponent } from './dashboard/main/services/services.component';
import { OurMissionComponent } from './dashboard/shared/our-mission/our-mission.component';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ProfileCardComponent } from './dashboard/shared/our-team/profile-card/profile-card.component';
import { AboutComponent } from './dashboard/main/about/about.component';
import { ReviewComponent } from './dashboard/shared/review/review.component';
import { ImageSliderComponent } from './dashboard/shared/review/image-slider/image-slider.component';
import { WhatWeAreWorkingOnComponent } from './dashboard/shared/what-we-are-working-on/what-we-are-working-on.component';
import { BlogCardComponent } from './dashboard/main/blogs/blog-card/blog-card.component';
import { SingleBlogComponent } from './dashboard/main/blogs/single-blog/single-blog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app';

firebase.initializeApp(environment.firebaseConfig);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PageNotFoundComponent,
    NavBarComponent,
    FooterComponent,
    MainComponent,
    HomeMainComponent,
    SharedServicesComponent,
    ServicesComponent,
    AboutUsComponent,
    FeaturedWorkComponent,
    HowWeWorkComponent,
    OurTeamComponent,
    QuotesComponent,
    ContactFormComponent,
    HomeComponent,
    PortfolioComponent,
    ImageSliderComponent,
    BlogsComponent,
    ContactComponent,
    OurMissionComponent,
    ProfileCardComponent,
    AboutComponent,
    ReviewComponent,
    WhatWeAreWorkingOnComponent,
    BlogCardComponent,
    SingleBlogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbCollapseModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSpinnerModule,
    AnimateOnScrollModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return async () => {
          await appConfigService.loadAppConfig();
        };
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
