import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Utility } from '../util/app-utils';
import { ContactService } from 'src/app/services/contact.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  public contactForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  keyMapping = {
    userName: 'user name',
    email: 'email',
    subject: 'subject',
    message: 'message',
  };
  errors = [];

  constructor(
    private contactService: ContactService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  async sendMessage() {
    this.spinner.show();
    this.errors = [];
    const isVaild = await Utility.isFormVaild(this.contactForm);
    this.errors = await Utility.getErrorListFromForm(
      this.contactForm,
      this.keyMapping
    );
    if (isVaild && this.errors.length === 0) {
      this.errors = await this.contactService.addContact(
        this.contactForm.value
      );
      await this.resetContactFormGroup();
      this.spinner.hide();
    }
  }

  resetContactFormGroup() {
    this.contactForm.reset('');
    Object.keys(this.contactForm.controls).forEach((control) => {
      this.contactForm.controls[control].markAsUntouched();
      this.contactForm.get(control).setErrors(null);
    });
  }
}
