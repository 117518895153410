<div class="about-us-main">
  <div class="container container-about-us">
    <div class="row justify-content-end">
      <div class="col-lg-5 offset-lg-1">
        <div
          class="about-section hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__fadeInLeft"
        >
          <h3 class="main-header">About Us</h3>
          <h2 class="second-header">Empowering individuals</h2>
          <p class="about-us-p">
            Encouraging each individual in there work, Creating an environment
            for them to work with enthusiasm and to always improve themself and
            there surrounding.
          </p>
          <p class="about-us-p text-italic">
            “The strength of the team is each individual member. The strength of
            each member is the team.”
          </p>
          <p class="about-us-p text-italic">― Phil Jackson</p>
          <button class="filled-btn mt-4" (click)="goToBlogs()">
            Check Us!
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
