<div class="how-we-work-main">
  <div class="container">
    <div class="row mg-0">
      <div class="col-lg-5">
        <div class="work-info">
          <h3
            class="work-header hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInLeft"
          >
            How we work
          </h3>
          <p
            class="work-p hide-on-init hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInLeft"
          >
            "Moving forward, together"
          </p>
          <p
            class="sab-p hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInLeft"
          >
            We are deeply committed to support our members. We support there
            ideas and want to get people to work on great things.
          </p>
          <p
            class="sab-p hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInLeft"
          >
            Go fast and fail fast.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
