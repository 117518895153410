import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
  NgbCarousel,
  NgbSlideEvent,
  NgbSlideEventSource,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  @Input() imagesData = [];
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  constructor() {}

  @Input() paused = false;
  @Input() unpauseOnArrow = false;
  @Input() pauseOnIndicator = false;
  @Input() pauseOnHover = false;
  @Input() sliderTimer = 6000;

  ngOnInit(): void {
    if (!(this.imagesData && this.imagesData.length)) {
      this.imagesData = [
        {
          fullName: 'Shubham Dohare',
          position: 'Founder & CEO ',
          message: 'Thanks for visiting us!',
        },
      ];
    }
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (
      this.unpauseOnArrow &&
      slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT ||
        slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
    ) {
      this.togglePaused();
    }
    if (
      this.pauseOnIndicator &&
      !slideEvent.paused &&
      slideEvent.source === NgbSlideEventSource.INDICATOR
    ) {
      this.togglePaused();
    }
  }
}
