import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from 'src/app/constants/app-routes-constants';
import { AppConstants } from 'src/app/constants/app-constants';
import { ContactService, PageNoEnum } from 'src/app/services/contact.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  LOGO_IMG_URL = AppConstants.LOGO_IMG_URL;
  public selectedOption = 1;
  public isScrolled = false;
  public contactNameSub;
  navStack = [this.selectedOption];
  @ViewChild('menu') menu: ElementRef;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.navStack && this.navStack.length) {
      this.navStack.pop();
      this.selectedOption = this.navStack[this.navStack.length - 1];
    }
  }

  @HostListener('document:click', ['$event'])
  clickEvent(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.setisNavbarCollapsed();
    }
  }

  constructor(
    private router: Router,
    public contactService: ContactService,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.setScrollEvent();
    this.initNavListener();
    if (window.location.pathname.includes('/blogs/')) {
      this.selectedOption = 4;
      this.navStack.push(this.selectedOption);
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    this.navStack = [];
    this.contactNameSub.unsubscribe();
  }

  initNavListener() {
    this.contactNameSub = this.contactService
      .getNavChangeListener()
      .subscribe((navNo) => {
        if (navNo) {
          switch (Number(navNo)) {
            case PageNoEnum.Home:
              this.goToHome();
              break;
            case PageNoEnum.SERVICE:
              this.goToService();
              break;
            case PageNoEnum.PORTFOLIO:
              this.goToPortfolio();
              break;
            case PageNoEnum.BLOG:
              this.goToBlogs();
              break;
            case PageNoEnum.CONTACT:
              this.goToContact();
              break;
            case PageNoEnum.ABOUT:
              this.goToAbout();
              break;
          }
        }
      });
  }

  setScrollEvent() {
    // const doesHaveLandPage = AppConstants.ALLOWED_ROUTES_URL.includes(
    //   window.location.pathname
    // );
    // if (doesHaveLandPage) {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        const footer = document.getElementById('footer');
        footer.style.display = 'block';
        this.isScrolled = true;
      } else {
        const footer = document.getElementById('footer');
        footer.style.display = 'none';
        this.isScrolled = false;
      }
    };
    // } else {
    //   this.isScrolled = true;
    // }
  }

  goToHome() {
    this.router.navigate([RouteConstants.HOME]);
    this.selectedOption = 1;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }
  goToService() {
    this.router.navigate([RouteConstants.SERVICES]);
    this.selectedOption = 2;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }

  goToPortfolio() {
    this.router.navigate([RouteConstants.PROTPOLIO]);
    this.selectedOption = 3;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }

  goToBlogs() {
    this.router.navigate([RouteConstants.BLOGS]);
    this.selectedOption = 4;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }

  goToContact() {
    this.router.navigate([RouteConstants.CONTACT]);
    this.selectedOption = 5;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }

  goToAbout() {
    this.router.navigate([RouteConstants.ABOUT]);
    this.selectedOption = 6;
    this.navStack.push(this.selectedOption);
    this.fixPageAfterNavigation();
  }

  fixPageAfterNavigation() {
    this.setisNavbarCollapsed();
    this.scrollToTop();
  }

  setisNavbarCollapsed() {
    const isMenuOpen =
      this.menu.nativeElement.getAttribute('aria-expanded') === 'true';
    if (isMenuOpen) {
      this.menu.nativeElement.click();
    }
  }

  scroll() {
    const contectElement: HTMLElement = document.getElementById('contact');
    if (contectElement) {
      contectElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    } else {
      this.contactService.navChangeListener.next(PageNoEnum.CONTACT);
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
