<div class="blog-card" *ngIf="blogData">
  <img
    class="blog-card-img"
    [src]="utility.getServerImageUrl(blogData.blogIcon.formats.small.url)"
  />
  <div class="blog-card-right">
    <div class="blog-card-right-title">
      {{ blogData.title }}
    </div>
    <div class="blog-card-right-desc">
      {{ blogData.stortDesc }}
    </div>
  </div>
</div>
