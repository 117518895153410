<div class="blog">
  <div class="main-home">
    <div class="container container-home">
      <div class="row align-items-center justify-content-start">
        <div class="col-lg-10 col-md-10">
          <div class="main-text-div">
            <h3
              class="main-text hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            >
              Blogs
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-service">
    <div class="container container-div">
      <div
        class="service-header-text justify-content-center mb-5 hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeInUp"
      >
        We love to share things that we have learned here.
      </div>
      <div class="row mg-0">
        <div
          *ngIf="!(blogData && blogData.length)"
          animateOnScroll
          animationName="animate__animated animate__fadeInUp"
          class="col-xl-12 col-md-12 col-lg-12 col-12 card-size-div"
        >
          <div class="card-main">Sorry No Data Found</div>
        </div>

        <div *ngIf="blogData && blogData.length">
          <app-blog-card
            class="col-xl-12 col-md-12 col-lg-12 col-12 card-size-div blog-card-main"
            *ngFor="let blog of blogData"
            [blogData]="blog"
            (click)="goToBlog(blog._id)"
          ></app-blog-card>
        </div>
      </div>
    </div>
  </div>
</div>
