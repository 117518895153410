export class AppConstants {
  public static STATUS_VAILD = 'VALID';
  public static LOGO_IMG_URL = '/assets/images/logo.png';
  public static ALLOWED_ROUTES_URL = [
    '/home',
    '/services',
    '/portfolio',
    '/blogs',
    '/contact',
  ];
}
