import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from './constants/api-constants';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  appConfig: any;

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('../assets/config/config.json')
      .toPromise()
      .then((data) => {
        this.appConfig = data;
        ApiConstants.setData(this.appConfig.API_SERVER);
      });
  }
}
