<div class="main-service">
  <div class="container container-div">
    <div
      class="service-header justify-content-center mb-4"
      hide-on-init
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      Services
    </div>
    <div
      class="service-header-text justify-content-center mb-5 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      We love to work on exciting things and with our great team we provide
      great quality products.
    </div>

    <div class="row mg-0">
      <div class="col-xl-3 col-md-6 col-lg-6 col-12 card-size-div">
        <div
          class="card-main hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__pulse"
        >
          <div class="icon">
            <img
              loading="lazy"
              src="/assets/images/ml.png"
              alt=""
              class="hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            />
          </div>
          <h3 class="mt-3 card-name">Machine Learning</h3>
          <p class="card-p">
            We can build ML models using sklean and deep leaning models using
            tensorflow or pytorch.
          </p>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 col-12 card-size-div">
        <div
          class="card-main hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__pulse"
        >
          <div class="icon">
            <img
              loading="lazy"
              src="/assets/images/research.png"
              alt=""
              class="hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            />
          </div>
          <h3 class="mt-3 card-name">Reinforcement Learning and Research</h3>
          <p class="card-p">
            We love to work on Reinforcement Learning, it is our main focus.
          </p>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-6 col-12 card-size-div">
        <div
          class="card-main hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__pulse"
        >
          <div class="icon">
            <img
              loading="lazy"
              src="/assets/images/web.png"
              alt=""
              class="hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            />
          </div>
          <h3 class="mt-3 card-name">Web Development</h3>
          <p class="card-p">
            We can build responsive scalable website using MEAN stack.
          </p>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-6 col-12 card-size-div">
        <div
          class="card-main hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__pulse"
        >
          <div class="icon">
            <img
              loading="lazy"
              src="/assets/images/native-dev.png"
              alt=""
              class="hide-on-init"
              animateOnScroll
              animationName="animate__animated animate__fadeInUp"
            />
          </div>
          <h3 class="mt-3 card-name">Native Development</h3>
          <p class="card-p">
            We can build native as well as hybrid Application.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
