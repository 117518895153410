<div class="main-home">
  <div class="container container-home">
    <div class="row align-items-center justify-content-start">
      <div class="col-lg-10 col-md-10">
        <div class="main-text-div">
          <h3
            class="main-text hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
          >
            Blogs
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Blog data -->
<div class="blog" *ngIf="blogData">
  <div class="blog-title">
    {{ blogData.title }}
  </div>
  <pre class="blog-data" style="white-space: pre-wrap">
    {{ blogData.blogData }}
  </pre>

  <div class="blog-author">Author: {{ blogData.author }}</div>
</div>
