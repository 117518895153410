import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.scss'],
})
export class SingleBlogComponent implements OnInit {
  private routeSub: Subscription;
  public blogData: any;
  constructor(
    private route: ActivatedRoute,
    public contactService: ContactService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.getBlog(params.id);
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  async getBlog(id) {
    this.blogData = await this.contactService.getSignleBlog(id);
  }
}
