import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

export class SnackbarUtil {
  static toastrService: ToastrService;

  static onSnackbarMessageAdded = new Subject();

  /**
   * Return observable for snack bar.
   */
  static getSnackBarAddedLister() {
    return SnackbarUtil.onSnackbarMessageAdded.asObservable();
  }

  static showMessage(message, isError = true, timeOut = 5000) {
    if (isError) {
      this.toastrService.error(message, '', { timeOut });
    } else {
      this.toastrService.success(message, '', { timeOut });
    }
  }
}
