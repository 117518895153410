<ngx-spinner></ngx-spinner>
<div class="main-contact" id="contact">
  <div class="container container-div">
    <div
      class="contact-header justify-content-center mb-4 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      Get in Touch
    </div>
    <div
      class="contact-header-text justify-content-center mb-5 hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
    >
      We'd love to hear from you. Please feel free to drop your information. We
      will contact you as soon as possible.
    </div>

    <div class="row mg-0">
      <form class="contact-form row" [formGroup]="contactForm">
        <div class="col-md-6 col-12 col-lg-6 col-xl-6">
          <mat-form-field
            appearance="fill"
            animateOnScroll
            class="hide-on-init"
            animationName="animate__animated animate__fadeInUp"
          >
            <mat-label>Your Name</mat-label>
            <input matInput type="text" formControlName="userName" />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-12 col-lg-6 col-xl-6">
          <mat-form-field
            appearance="fill"
            animateOnScroll
            class="hide-on-init"
            animationName="animate__animated animate__fadeInUp"
          >
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
          </mat-form-field>
        </div>
        <div class="col-md-12 col-12 col-lg-12 col-xl-12">
          <mat-form-field
            appearance="fill"
            class="hide-on-init"
            animateOnScroll
            animationName="animate__animated animate__fadeInUp"
          >
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="subject" />
          </mat-form-field>
        </div>
        <div class="col-md-12 col-12 col-lg-12 col-xl-12">
          <mat-form-field
            appearance="fill"
            animateOnScroll
            class="hide-on-init"
            animationName="animate__animated animate__fadeInUp"
          >
            <mat-label>Message</mat-label>
            <textarea
              class="message-text"
              matInput
              rows="5"
              maxlength="2024"
              formControlName="message"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-12 col-lg-12 col-xl-12">
          <button
            class="filled-btn send-msg-btn hide-on-init"
            (click)="sendMessage()"
            animateOnScroll
            animationName="animate__animated animate__zoomIn"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
