import { Component, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from './dashboard/shared/util/app-utils';
import { SnackbarUtil } from './dashboard/shared/util/snackbar-util';
import { ToastrService } from 'ngx-toastr';
import { RouteConstants } from './constants/app-routes-constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as firebase from 'firebase/app';
// declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'Infielight';

  constructor(
    private translate: TranslateService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    translate.setDefaultLang('en');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        firebase.analytics().logEvent('routeEvent', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    Utility.translate = this.translate;
    SnackbarUtil.toastrService = this.toastrService;
    if (window.location.pathname.includes('/blogs/')) {
      this.router.navigate([window.location.pathname]);
    } else {
      this.router.navigate([RouteConstants.HOME]);
    }
  }

  ngAfterViewInit() {
    firebase.analytics().logEvent('eventName', {
      firsttimeuser: true,
      username: 'username',
    });
  }
}
